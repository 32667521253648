import React, {
  createContext, useContext, useCallback, useReducer, useEffect
} from 'react';
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';

import {
  NOTIFICATION_DISPLAY_DURATION as duration,
  NOTIFICATION_MAX_VISIBLE,
} from 'Constants';

import { useRouter } from './hooks';

export const NotificationsContext = createContext();

const reducer = (state, { type: action, payload }) => {
  switch (action) {
    case 'add':
      // make sure to restrict the number of visible notifications at a time
      if (state.length >= NOTIFICATION_MAX_VISIBLE) {
        const rm = state.splice(0, NOTIFICATION_MAX_VISIBLE - state.length + 1);
        rm.forEach((n) => clearTimeout(n.TO));
      }

      return [...state, payload];
    case 'remove':
      return state.filter((d) => {
        if (d.id !== payload.id) {
          return true;
        }

        clearTimeout(d.TO);
        return false;
      });
    default:
      return state;
  }
};

export const NotificationsCtxProvider = (props) => {
  const { pathname } = useRouter();
  const [notifications, dispatch] = useReducer(reducer, []);

  const hide = useCallback((payload) => dispatch({ type: 'remove', payload }), []);

  const show = useCallback((data, options) => {
    const id = _uniqueId('notif_');

    const payload = {
      id,
      data: { ...data, [data.type || 'info']: true },
      options,
      TO: setTimeout(dispatch, _get(options, 'duration', duration) * 1000, { type: 'remove', payload: { id } }),
    };

    dispatch({ type: 'add', payload });
  }, []);

  const parseParams = (data, type) => (
    typeof data === 'string' ? { content: data, type } : { ...data, type }
  );
  const showSuccess = useCallback((data, options) => show(parseParams(data, 'positive'), options), [show]);
  const showError = useCallback((data, options) => show(parseParams(data, 'negative'), options), [show]);
  const showWarning = useCallback((data, options) => show(parseParams(data, 'warning'), options), [show]);

  // on unmount, clear all timeouts
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => notifications.forEach(({ TO }) => clearTimeout(TO)), []);

  // eslint-disable-next-line
  useEffect(() => (notifications.forEach((n) => hide(n))), [pathname, hide]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        show,
        showSuccess,
        showError,
        showWarning,
        hide,
      }}
      {...props}
    />
  );
};

export const useNotifications = () => useContext(NotificationsContext);
