import { lazy } from 'react';
import { PERMISSION_SCHEDULE } from 'Constants';
import { IconEditAdd } from '@make.tv/lib-web-uicomponents';

export default {
  ui: 'menuLeft',
  auth: { role: PERMISSION_SCHEDULE },
  path: 'scheduling',
  title: 'Scheduling',
  urlParams: '/:type?/:id?',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/active',
      component: () => null,
      title: 'View Schedule',
    },
    {
      path: '/running',
      component: () => null,
      title: 'Running Bookings',
    },
    {
      path: '/past',
      component: () => null,
      title: 'Past Bookings',
    },
    {
      path: '/cancelled',
      component: () => null,
      title: 'Cancelled Bookings',
    },
    {
      path: '/search/:id',
      component: () => null,
      ui: 'hidden',
    },
    {
      path: '/connectors',
      urlParams: '/:view?/:id?',
      component: lazy(() => import('./views/connectors-view')),
      title: 'Connectors',
    },
    {
      path: '/distribution-groups',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/distribution-groups-view')),
      title: 'Distribution Groups',
    },
    {
      path: '/mass-update',
      urlParams: '/:oldSrcId?/:newSrcId?',
      component: lazy(() => import('./views/mass-update-view')),
      title: 'Mass Update',
    },
    {
      path: '/booking',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/booking-view')),
      title: 'New Booking',
      icon: IconEditAdd(),
      color: 'primary',
      id: 'newBookingBtn',
    },
    {
      path: '/billing',
      urlParams: '/:id?/modify',
      component: lazy(() => import('./views/billing-view')),
      ui: 'hidden',
      title: 'Modify Billing',
    },
  ],
};
