import theme from 'styled-theming';
import { css } from 'styled-components';

const h1 = css`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.167;
  letter-spacing: -0.01562em;
`;
const h2 = css`
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.00833em;
`;
const h3 = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.167;
  letter-spacing: 0em;
`;
const h4 = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.235;
  letter-spacing: 0.00735em;
`;

const h5 = css`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.334;
  letter-spacing: 0em;
`;
const h6 = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`;
const subtitle1 = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.75;
  letter-spacing: 0.00938em;
`;
const subtitle2 = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  letter-spacing: 0.00714em;
`;
const body1 = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;
const body2 = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.32;
  letter-spacing: 0.01071em;
`;
const body3 = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.01071em;
`;
const button = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
`;
const link = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.75;
  letter-spacing: 0.02857em;
`;
const caption = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;
const overlay = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 2.66;
  letter-spacing: 0.08333em;
  text-transform: uppercase;
`;

export default theme('variant', {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  subtitle1,
  subtitle2,
  body1,
  body2,
  body3,
  button,
  link,
  caption,
  overlay,
});
