import styled from 'styled-components';

import whiteLogo from 'assets/images/ltn-logo-white.svg';

export const Wrap = styled.div.attrs({ className: 'd-flex flex-none' })`
  background: ${({ theme }) => theme.colors.navBg};
`;

export const Logo = styled.img.attrs(() => ({ src: whiteLogo }))`
  display: block;
  width: 50px;
  opacity: .6;
`;
