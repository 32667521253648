import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { LS_NAMESPACE } from 'Constants';
import { useUser } from 'Context/user';
import { useNavContext } from 'Context/nav-context';
import { Typography } from 'Components/typography';
import * as cookieStorage from 'Services/cookies.service';

import { UserDate } from './date/user-date';

export const readServerTime = () => {
  const localTimeDiff = cookieStorage.getItem(`${LS_NAMESPACE}/local-time-diff`) || localStorage.getItem(`${LS_NAMESPACE}/local-time-diff`);
  return new Date(Date.now() - parseInt(localTimeDiff, 10));
};

export const readServerTimestamp = () => (
  cookieStorage.getItem(`${LS_NAMESPACE}/server-time`) || localStorage.getItem(`${LS_NAMESPACE}/server-time`)
);

/**
 * Component to indicate current time & timezone offset
 */
const Wrap = styled(Typography).attrs({ variant: 'button', className: 'px-1 mx-1' })`
  border: 1px solid;
  border-radius: ${({ theme }) => theme.radius.md}px;
  font-size: 95%;
  min-width: 110px;
  padding: 4px 5px;
  text-align: center;
  color: ${({ theme }) => theme.colors.link};
`;

export const CurrentTime = ({ className }) => {
  const { user, timezone } = useUser();
  const { resetClock, setResetClock } = useNavContext();

  const [time, setTime] = useState(readServerTimestamp());
  const to = useRef();

  useEffect(() => {
    setResetClock(false);
    to.current = setTimeout(() => setTime(readServerTimestamp()), 1000);
    return () => clearTimeout(to.current);
  }, [resetClock, setResetClock]);

  if (!user) {
    return null;
  }

  return (
    <Wrap className={className}>
      <UserDate format={`HH:mm:ss '${timezone}'`} date={time} />
    </Wrap>
  );
};
