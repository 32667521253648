import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@make.tv/lib-web-uicomponents';
import { RoleGuard } from 'Components/auth/role-guard';
import { SubNav as SubNavStyled } from './styles';

const PATH_VIEW_GRID = '/grid';

function buildRoute(activeRoute, route, pathnameCurrent) {
  const routeNew = `/${activeRoute.path}${route.path}`;

  if (route.hasViewGrid && pathnameCurrent.endsWith(PATH_VIEW_GRID)) {
    return `${routeNew}${PATH_VIEW_GRID}`;
  }

  return routeNew;
}

export const SubNav = ({ activeRoute, pathname, titleOverwrite }) => {
  if (!activeRoute || !activeRoute.children || activeRoute.children.length === 0) {
    return null;
  }

  return (
    <SubNavStyled style={{ margin: '14px 0 0 14px' }}>
      {activeRoute.children.filter((route) => route.ui !== 'hidden').map((route) => (
        <RoleGuard {...route.auth} key={route.path}>
          <Link
            to={buildRoute(activeRoute, route, pathname)}
            exact={(route.exact || !route.path) || undefined}
          >
            <Button color={route.color || 'tertiary'} id={route.id} active={pathname.includes(`/${activeRoute.path}${route.path}`)}>
              {route.icon}
              <span>{titleOverwrite && titleOverwrite[route.path] ? `${route.title} (${titleOverwrite[route.path]})` : route.title}</span>
            </Button>
          </Link>
        </RoleGuard>
      ))}
    </SubNavStyled>
  );
};
