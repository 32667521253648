import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';

const queryConfig = {
  queries: {
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  },
};

export const AppQuery = ({ children }) => (
  <ReactQueryConfigProvider config={queryConfig}>
    {children}
  </ReactQueryConfigProvider>
);
