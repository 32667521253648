import React, {
  createContext, useContext, useCallback, useRef, useState, useEffect, useMemo
} from 'react';

import { useUser } from 'Context/user';
import { APP_THEME_LIGHT, APP_THEME_DARK, DEFAULT_THEME } from 'Constants';

// import { useLocalStorage } from './hooks/use-storage';

const ThemeContext = createContext();

export const ThemeCtxProvider = (props) => {
  const { dataReady, setTheme, theme: userTheme } = useUser();
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const [viewWidth, setViewWidth] = useState(window.innerWidth);

  // remove theme from cookies since it is not used (at least for now)
  // const [mode, setMode] = useLocalStorage('theme/mode', DEFAULT_THEME);
  const [mode, setMode] = useState(DEFAULT_THEME);

  const { current: modes } = useRef({
    dark: APP_THEME_DARK,
    light: APP_THEME_LIGHT,
  });

  const inverted = useMemo(() => mode === modes.dark || null, [mode, modes.dark]);

  const toggleMode = useCallback(() => {
    setMode(mode === APP_THEME_LIGHT ? APP_THEME_DARK : APP_THEME_LIGHT);
  }, [mode, setMode]);

  // set the default theme mode on logout & login
  useEffect(() => {
    if (dataReady) {
      setMode(userTheme || DEFAULT_THEME);
    }
  }, [setMode, userTheme, dataReady]);

  // save current theme to user's preference
  // disable hooks check for this. we want to update the user theme only when the mode changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setTheme(mode); }, [mode]);

  useEffect(() => {
    const onResize = () => {
      setViewHeight(window.innerHeight);
      setViewWidth(window.innerWidth);
      document.body.style.setProperty('--width', window.innerWidth);
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        mode,
        setMode,
        toggleMode,
        modes,
        inverted,
        vars: {
          viewHeight, viewWidth
        },
      }}
      {...props}
    />
  );
};

export const useTheme = () => useContext(ThemeContext);

export const withTheme = (Component) => (props) => (
  <Component theme={useTheme()} {...props} />
);
