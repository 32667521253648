import { tagged } from 'Styles/utils';

import colors from './colors';
import breakpoints from './breakpoints';
import typography from './typography';
import transitions from './transitions';

export default {
  typography,
  colors,
  breakpoints,
  transitions,

  minSiteWidth: 1200,

  grid: {
    cols: 12,
  },

  queries: Object.keys(breakpoints).reduce((p, c) => {
    p[c] = tagged((css) => `@media (min-width: ${breakpoints[c]}px) {${css}}`);
    return p;
  }, {}),

  radius: {
    sm: 3,
    md: 5,
  }
};
