import React, {
  createContext, useContext, useEffect, useCallback, useState, useMemo
} from 'react';
import { queryCache } from 'react-query';
import get from 'lodash/get';
import TagManager from 'react-gtm-module';
import { sha256 } from 'js-sha256';

import { env } from 'Constants';
import { Users, Accounts } from 'Services/resources';
import { error as logError } from 'Utils/logger';

import { useAuth } from './auth';
import { useLocalStorage } from './hooks/use-storage';

const UserContext = createContext();

export const UserProvider = (props) => {
  const [dataReady, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gtagInitialized, setGtagInitialized] = useState(false);
  const { data: jwt, token } = useAuth();
  const [user, setUser] = useLocalStorage('userData', null, { listenForChanges: true });
  const [account, setAccount] = useLocalStorage('accountData', null, { listenForChanges: true });
  const [realAccount, setRealAccount] = useLocalStorage('realAccountData', null, { listenForChanges: true });
  const [acceptedLocalCookies, setAcceptedLocalCookies] = useLocalStorage('acceptedCookies', null, { listenForChanges: true });
  const [userWatchEndpoint, setUserWatchEndpoint] = useLocalStorage('userWatchEndpointData', undefined);

  const userTheme = useMemo(() => (
    get(user, 'theme', '').toLowerCase()
  ), [user]);

  const userTimezone = useMemo(() => (
    get(user, 'timezone', 'GMT')
  ), [user]);

  const userDateFormat = useMemo(() => {
    const fmt = get(user, 'date_format', 'MONTH_DAY_YEAR');

    if (fmt === 'YEAR_MONTH_DAY') {
      return 'yyyy/MM/dd HH:mm';
    } if (fmt === 'DAY_MONTH_YEAR') {
      return 'dd/MM/yyyy HH:mm';
    }
    return 'MM/dd/yyyy HH:mm';
  }, [user]);

  const fetchUser = useCallback(() => {
    setLoading(true);

    return Users.get(jwt.user_id)
      .then(async (userData) => {
        setUser(userData);

        await Accounts.get(userData.account_id).then(setRealAccount);
        if (userData.emulated_account_id && userData.emulated_account_id !== userData.account_id) {
          return Accounts.get(userData.emulated_account_id).then(setAccount);
        }

        return Accounts.get(userData.account_id).then(setAccount);
      })
      .then(() => { setLoading(false); });
  }, [jwt, setUser, setAccount, setRealAccount]);

  const impersonate = useCallback((id) => (
    Users.impersonate(user.user_id, id).then(fetchUser)
  ), [user, fetchUser]);

  const setTheme = useCallback((theme) => (
    (user && (!user.theme || user.theme.toLowerCase() !== theme))
    && Users.setTheme(user.user_id, theme).then(fetchUser)
  ), [user, fetchUser]);

  const hasRole = useCallback((role) => {
    const roles = [...get(user, 'permissions', []), ...get(account, 'permissions', [])];
    return !role || roles.indexOf(role) > -1;
  }, [user, account]);

  const hasRealRole = useCallback((role) => {
    const roles = [...get(user, 'permissions', []), ...get(realAccount, 'permissions', []), ...get(account, 'permissions', [])];
    return !role || roles.indexOf(role) > -1;
  }, [user, realAccount, account]);

  const isImpersonating = useMemo(() => (
    user
    && user.emulated_account_id
    && user.account_id !== user.emulated_account_id
  ), [user]);

  const impersonatedAccount = useMemo(() => (
    isImpersonating && user.emulated_account_id
  ), [isImpersonating, user]);

  useEffect(() => {
    queryCache.clear();
  }, [account]);

  useEffect(() => {
    if (loading) {
      console.log('User loading');
      return;
    }

    if (!jwt && token) {
      console.log('JWT is decoding');
      return;
    }

    if (jwt && (!user || !account || !realAccount)) {
      console.log('User loading, fetching');

      setReady(false);
      fetchUser().catch(logError).then(() => setReady(true));
      return;
    }

    if (!jwt) {
      console.log('No user');
      setUser(undefined);
      setAccount(undefined);
      setRealAccount(undefined);
      setReady(true);
      return;
    }

    setReady(true);
  }, [
    jwt,
    user,
    fetchUser,
    setUser,
    loading,
    account,
    setAccount,
    realAccount,
    setRealAccount,
    token,
  ]);

  useEffect(() => {
    if (!gtagInitialized && user && user.cookies_acception !== 'ESSENTIAL_ONLY') {
      TagManager.initialize({
        gtmId: env.REACT_APP_GTM_ID,
        dataLayer: {
          userId: sha256(user.user_id),
          accountId: sha256(user.account_id),
        },
      });
      setGtagInitialized(true);
    }
  }, [user, gtagInitialized, setGtagInitialized]);

  return (
    <UserContext.Provider
      value={{
        user,
        account,
        realAccount,
        acceptedLocalCookies,
        setAcceptedLocalCookies,
        fetchUser,
        loading,
        dataReady,
        hasRole,
        hasRealRole,
        impersonate,
        isImpersonating,
        impersonatedAccount,
        theme: userTheme,
        timezone: userTimezone,
        dateFormat: userDateFormat,
        setTheme,
        userWatchEndpoint,
        setUserWatchEndpoint,
        // isAuthenticated: Boolean(jwt),
      }}
      {...props}
    />
  );
};

export const useUser = () => useContext(UserContext);
