import { lazy } from 'react';
import { PERMISSION_NOC } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: PERMISSION_NOC },
  path: 'noc',
  title: 'TOC',
  urlParams: '/:type?',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/alarms',
      component: lazy(() => import('./alarms/index')),
      title: 'Alarms',
      pageTitle: 'Alarms',
    },
    {
      path: '/alarm-exclusions',
      component: lazy(() => import('./alarm-exclusions/views/index')),
      title: 'Alarm Exclusions',
      pageTitle: 'Alarm Exclusions',
    },
    {
      path: '/alarm-exclusion',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./alarm-exclusions/views/alarm-exclusion-view')),
      ui: 'hidden',
    },
    {
      path: '/sites',
      component: lazy(() => import('./sites/index')),
      title: 'Sites',
      pageTitle: 'Sites',
    },
    {
      path: '/site',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./sites/views/site-view')),
      ui: 'hidden',
    },
    {
      path: '/endpoint',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./sites/views/endpoint-view')),
      ui: 'hidden',
    },
    {
      path: '/hardware',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./sites/views/hardware-view')),
      ui: 'hidden',
    },
    {
      path: '/deployment',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./sites/views/deployment-view')),
      ui: 'hidden',
    },
    {
      path: '/isp-circuit',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./sites/views/isp-circuit-view')),
      ui: 'hidden',
    },
    {
      path: '/quick-add-site',
      component: lazy(() => import('./sites/views/quick-add-site-view')),
      ui: 'hidden',
    },
    {
      path: '/quick-add-service',
      component: lazy(() => import('./sites/views/quick-add-service-view')),
      ui: 'hidden',
    },
    {
      path: '/channels',
      component: lazy(() => import('./channels/views/index')),
      title: 'Channels',
      pageTitle: 'Channels',
    },
    {
      path: '/channel',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./channels/views/channel-view')),
      ui: 'hidden',
    },
    {
      path: '/leaves',
      component: lazy(() => import('./leaves/views/index')),
      title: 'Leaves',
      pageTitle: 'Leaves',
    },
    {
      path: '/leaf',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./leaves/views/leaf-view')),
      ui: 'hidden',
    },
    {
      path: '/flowclients',
      component: lazy(() => import('./flowclients/views/index')),
      title: 'Flowclients',
      pageTitle: 'Flowclient Channel Configuration Panel',
    },
    {
      path: '/flowclient',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./flowclients/views/flowclient-view')),
      ui: 'hidden',
    },
    {
      path: '/billing',
      component: lazy(() => import('./billings/views/index')),
      title: 'Billing',
      pageTitle: 'Billing',
    },
    {
      path: '/call-log',
      component: lazy(() => import('./call-log/index')),
      title: 'Call-Log',
      pageTitle: 'Call-Log',
    },
    {
      path: '/contacts',
      urlParams: '/:type?/:id?',
      component: lazy(() => import('./contacts/views/index')),
      title: 'Contacts',
      pageTitle: 'Contacts',
      children: [
        {
          path: '/search/:id',
          component: () => null,
          ui: 'hidden',
        },
      ]
    },
    {
      path: '/contact',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./contacts/views/contact-view')),
      ui: 'hidden',
    },
    {
      path: '/change-log',
      component: lazy(() => import('./change-log/index')),
      title: 'Change-Log',
      pageTitle: 'Change-Log',
    },
    {
      path: '/site_certifications',
      urlParams: '/:view?/:id?',
      component: lazy(() => import('./site-certifications/views/site-certification-view')),
      title: 'Site Certifications',
      pageTitle: 'Site Certifications',
    },
  ],
};
