import { lazy } from 'react';
import { ROLE_ACCOUNT_ADMIN, PERMISSION_NOC } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: [ROLE_ACCOUNT_ADMIN, PERMISSION_NOC] },
  path: 'account-admin',
  title: 'Account Admin',
  urlParams: '/:page?',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/tags',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/tags-view')),
      title: 'Tags',
      pageTitle: 'Tags',
    },
  ],
};
