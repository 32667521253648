import React, {
  createContext, useContext, useState, useEffect
} from 'react';

import * as Resources from 'Services/resources/resource';

const NavContext = createContext();

export const NavContextProvider = (props) => {
  const [titleOverwrite, setTitleOverwrite] = useState();
  const [resetClock, setResetClock] = useState(false);
  const [versionOutdated, setVersionOutdated] = useState();

  useEffect(() => {
    const currentVersion = process.env.REACT_APP_VERSION;

    const interval = setInterval(() => {
      Resources.get('latest_portal_version', '').then(({ portal_version_id: latestVersion }) => {
        setVersionOutdated(currentVersion !== latestVersion);
      });
    }, 1000 * 60 * 5);

    return () => clearInterval(interval);
  }, []);

  return (
    <NavContext.Provider
      value={{
        titleOverwrite,
        setTitleOverwrite,
        resetClock,
        setResetClock,
        versionOutdated,
      }}
      {...props}
    />
  );
};

export const useNavContext = () => useContext(NavContext);
