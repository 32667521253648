import React, { forwardRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';

const Component = styled.div`
  ${({ theme }) => theme.typography}
`;

const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  link: 'a',
};

export const Typography = forwardRef((props, ref) => {
  const {
    classes,
    className,
    component,
    paragraph = false,
    variant = 'body1',
    variantMapping = defaultVariantMapping,
    theme,
    ...other
  } = props;

  const elType = component
    || (paragraph ? 'p' : variantMapping[variant] || defaultVariantMapping[variant])
    || 'span';

  return (
    <ThemeProvider theme={{ ...theme, variant }}>
      <Component as={elType} className={className} ref={ref} {...other} />
    </ThemeProvider>
  );
});
