import React from 'react';

import { useUser } from 'Context/user';
import { DateFm } from './date-fm';

export const UserDate = ({
  date, format = null, dateOnly = false, includeSeconds = false, ...opts
}) => {
  const { timezone, dateFormat } = useUser();

  if (!format) {
    if (dateOnly) {
      [format] = dateFormat.split(' ');
    } else if (includeSeconds) {
      format = `${dateFormat}:ss`;
    } else {
      format = dateFormat;
    }
  }

  return (
    <DateFm date={date} format={format} {...opts} tz={timezone} />
  );
};
