import { css } from 'styled-components';

export const tagged = (fn) => (arr, ...args) => {
  const narr = [...arr];
  args.forEach((arg, i) => narr.splice(i * 2 + 1, 0, arg));
  return fn(narr.join(''));
};

export const modifier = (attr, value) => (...args) => (props) => (
  (value !== undefined ? props[attr] === value : props[attr]) ? css(...args) : null
);

export const modifiers = (...attrs) => (...args) => (props) => (
  attrs.every((attr) => props[attr]) ? css(...args) : null
);
