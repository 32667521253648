import React from 'react';
import { Button } from 'semantic-ui-react';

import { NotificationsContext } from 'Context/notifications';
import { Typography } from 'Components/typography';
import { log } from 'Utils/logger';

export class ErrorBoundary extends React.Component {
  static contextType = NotificationsContext;

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: Boolean(error.toString().match(/ChunkLoadError/)) };
  }

  componentDidCatch(error) {
    const { hasError } = this.state;

    if (!hasError) {
      const { showError } = this.context;
      const reload = () => window.location.reload(true);

      showError({
        content: (
          <div className="text-center">
            <Typography variatn="h1">
              There was an unexpected error, please reload the page if stuck.
            </Typography>
            <Button primary onClick={reload}>Reload</Button>
          </div>
        )
      }, { duration: 99999 });
    }

    log(error);
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      const reload = () => window.location.reload(true);

      return (
        <div className="text-center">
          <Typography variatn="h1">
            Your application seems to be outdated,
            please reload the page to load the latest version!
          </Typography>
          <Button primary onClick={reload}>Reload</Button>
        </div>
      );
    }

    const { children } = this.props;

    return children;
  }
}
