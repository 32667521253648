export const STATUS_OPTIONS = [
  { value: 'IN_PRODUCTION', text: 'In-Production (Active)' },
  { value: 'IN_PRODUCTION_INTERNAL', text: 'In-Production-Internal' },
  { value: 'TESTING', text: 'Testing' },
  { value: 'OFFLINE_REQUESTABLE', text: 'Offline-Requestable' },
  { value: 'OFFLINE_MAINTENANCE', text: 'Offline-Maintenance' },
  { value: 'OFFLINE_UNKNOWN', text: 'Offline-Unknown' },
  { value: 'NOT_IN_PRODUCTION', text: 'Not-In-Production NOC-Only Viewable' },
  { value: 'NOT_IN_PRODUCTION_CV', text: 'Not-In-Production Customer Viewable' },
  { value: 'INSTALL', text: 'Install' },
  { value: 'INACTIVE', text: 'Inactive' },
];
