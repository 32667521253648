import { lazy } from 'react';
import { PERMISSION_MONITOR } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: PERMISSION_MONITOR },
  path: 'monitoring',
  urlParams: '/:type?/:view?/:subview?',
  title: 'Monitoring',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/active',
      component: () => null,
      hasViewGrid: true,
      title: 'Active Channels',
      pageTitle: 'Monitoring Active Channels'
    },
    {
      path: '/my-channels',
      component: () => null,
      hasViewGrid: true,
      title: 'My Channels',
      pageTitle: 'Monitoring My Channels'
    },
    {
      path: '/all',
      component: () => null,
      hasViewGrid: true,
      title: 'All Channels',
      pageTitle: 'Monitoring All Channels'
    },
    {
      path: '/endpoints',
      component: () => null,
      title: 'Endpoints',
      pageTitle: 'Monitoring Endpoints'
    },
    {
      path: '/connector-leaves',
      component: () => null,
      title: 'Active Connector Leaves',
      pageTitle: 'Active Connector Leaves'
    },
  ],
};
