import * as Resource from './resource';
import * as userMethods from './users';
import * as bookingsMethods from './bookings';

export const Accounts = Resource.getResource('accounts');
export const BookingDetails = Resource.getResource('booking_details');
export const Contacts = Resource.getResource('contacts');
export const Leaves = Resource.getResource('leaves');
export const Flowclients = Resource.getResource('flowclients');
export const Channels = Resource.getResource('channels');
export const Hardware = Resource.getResource('hardware');
export const Connectors = Resource.getResource('connectors');
export const ConnectorLeaves = Resource.getResource('connector_leaves');
export const AWSEntitlements = Resource.getResource('aws_entitlements');
export const UsageByEndpoints = Resource.getResource('usage_by_endpoints');

// Bookings extends the general Resource, and adds additional methods described in ./bookings.js
export const Bookings = {
  ...Resource.getResource('bookings'),
  ...bookingsMethods,
};

// Users extends the general Resource, and adds additional methods described in ./users.js
export const Users = {
  ...Resource.getResource('users'),
  ...userMethods,
};

export { Resource };

export * from './directory-addresses';
