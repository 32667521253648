import { lazy } from 'react';
import { ROLE_LTN_ADMIN } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: ROLE_LTN_ADMIN },
  path: 'admin',
  title: 'Admin',
  urlParams: '/:page?',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/tags',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/tags-view')),
      title: 'Tags',
      pageTitle: 'Tags',
    },
    {
      path: '/hardware_models',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/hardware-models-view')),
      title: 'Hardware Models',
      pageTitle: 'Hardware Models',
    },
    {
      path: '/mobile_ingest_clients',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/mobile-ingest-clients-view')),
      title: 'Mobile Ingest Clients',
      pageTitle: 'Mobile Ingest Clients',
    },
    // {
    //   path: '/decoder_configuration_profiles',
    //   urlParams: '/:id?/:action?',
    //   component: lazy(() => import('./views/decoder-configuration-profiles-view')),
    //   title: 'Decoder Configuration Profiles',
    //   pageTitle: 'Decoder Configuration Profiles',
    // },
    // {
    //   path: '/encoder_configuration_profiles',
    //   urlParams: '/:id?/:action?',
    //   component: lazy(() => import('./views/encoder-configuration-profiles-view')),
    //   title: 'Encoder Configuration Profiles',
    //   pageTitle: 'Encoder Configuration Profiles',
    // },
    {
      path: '/thumbnailer_configuration_profiles',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/thumbnailer-configuration-profiles-view')),
      title: 'Thumbnailer Configuration Profiles',
      pageTitle: 'Thumbnailer Configuration Profiles',
    },
  ],
};
