import theme from 'styled-theming';

const mode = (light, dark) => theme('mode', { light, dark });

export default {
  primary: '#2185d0',

  text: mode('#111', '#d5d5d5'),
  bg: mode('#f0f0f0', '#26292c'),
  link: mode('#111', 'rgba(255,255,255,.5)'),
  linkActive: mode('#000', '#fff'),
  subLinkActive: mode('lightgray', '#26292c'),
  menuLinkBg: mode('rgba(0,0,0,.1)', 'rgba(255,255,255,.1)'),
  subMenuLinkBg: mode('rgba(0,0,0,.7)', 'rgba(255,255,255,.7)'),
  navBg: mode('#bbb', '#161616'),
  hoverBg: mode('rgba(255,255,255,.4)', 'rgba(255,255,255,.15)'),

  boxBg: mode('#fff', '#333'),
  boxBorder: mode('rgba(34,36,38,.15)', '#3d3e3f'),
  boxDropShadow: mode('rgba(34,36,38,.15)', 'rgba(34,36,38,.15)'),

  inputBg: mode('#fff', '#121314'),
  inputSelectedBg: mode('#fff', '#2a2d2f'),
  inputTextColor: '#c8c8c8',
  inputBorderColor: 'rgba(34,36,38,.15)',

  modalOverlayBg: mode('rgba(0,0,0,.85)', 'rgba(70, 70, 70, 85%)'),
  modalHeadBg: mode('#fff', '#1b1c1d'),
  modalContentBg: mode('#fff', '#26292c'),
  modalCloseColor: mode('rgba(0,0,0,.87)', 'rgba(255,255,255,.87)'),

  contentLink: '#4183c4',

  textSuccess: '#1a531b',
  textWarning: '#794b02',
  textError: '#912d2b',
  textInfo: '#0e566c',

  gray: mode('gray', '#777'),

  table: {
    background: mode('#fff', '#333'),
    backgroundHover: mode('#ededed', '#4f4f4f'),
    border: mode('rgba(34,36,38,.1)', 'rgba(34,36,38,.1)'),
    headerBg: mode('#f9fafb', 'rgba(0,0,0,.15)'),
    text: mode('rgba(0,0,0,.87)', 'rgba(0,0,0,.87)'),
  },

  watch: {
    default: '#808080',
    selected: '#3787d0',
    placeholderBg: mode('#e0e0e0', '#3e3e3e'),
  },

  uiTabs: {
    background: mode('#fff', '#4c4c4c'),
    text: mode('#000', '#fff'),
  },

  bookingStatus: {
    active: mode('#D1DAF6', '#4081d4'),
    cancelled: mode('#FFEEEE', '#FF3D3D'),
    completed: mode('#DAFA9C', '#6EA208'),
    completed_unacked: mode('#FFBB99', '#FB5400'),
    confirmed: mode('#FFF', '#333'),
    maintenance: mode('FFBB99', '#FB5400'),
    pending: mode('#FFBB99', '#FB5400'),
    requested: mode('#FFBB99', '#FB5400'),
    unknown: mode('#FFBB99', '#FB5400'),
    checkin: mode('#2184a7', '#2184a7'),
    checked: mode('#21a787', '#21a787'),
    missing: mode('#CC5A54', '#CC5A54'),
  },

  monitoringStatus: {
    green: '#4CC552',
    purple: '#9370db',
    yellow: '#FFD700',
    orange: '#FFD700',
    // orange: '#EE7700',
    red: '#CC5A54',
    gray: '#555',
    grey: '#555',
  },

  priorityColors: {
    red: '#F20D20',
    pink: '#EC4913',
    orange: '#E69119',
    yellow: '#C0A22A',
    green: '#5AB226',
  },

  popover: {
    hoverBg: mode('rgba(0, 0, 0, 0.05)', 'rgba(255, 255, 255, 0.15)')
  },
  siteStatus: {
    'in-production': { value: '#fff', a: 0 },
    testing: { value: '#2fff16' },
    install: { value: '#00a7ff' },
    inactive: { value: '#ff2f2f' },
    'not-in-production': { value: '#ff0' },
    offline: { value: '#ff732d' },
    'no-status---other': { value: '#FFF' },
  },

  statusChannel: {
    text: {
      color: '#000000',
    },
    hover: {
      background: '#525961',
    },
    active: {
      background: '#121314',
      color: '#FFFFFF',
    }
  }
};
