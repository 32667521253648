import { css } from 'styled-components';
import _times from 'lodash/times';

const spacings = ['auto', 0, '5px', '10px', '15px', '20px', '25px'];

export default css`
  .link {
    color: ${({ theme }) => theme.colors.contentLink};
  }

  [hidden] {
    display: none!important;
  }

  .hidden {
    display: none;
    visibility: hidden;
  }

  .fading {
      -webkit-animation-duration: 1s;
      -webkit-animation-name: fading;
      animation-duration: 1s;
      animation-name: fading;
  }
  @-webkit-keyframes fading {
      from {
          color: lightgray;
      }
      to {
          color: black;
      }
  }
  @keyframes fading {
      from {
          color: lightgray;
      }
      to {
          color: black;
      }
  }

  .no-wrap {
    white-space: nowrap;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-none {
    flex: 0 0 auto;
  }

  .flex-all {
    flex: 1 1 auto;
  }

  .flex-grow {
    flex-grow: 1;
  }

  .flex-shrink {
    flex-shrink: 1;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-row {
    flex-direction: row;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .relative {
    position: relative;
  }

  .pointing {
    cursor: pointer;
  }

  .blinker {
    animation: blink 1.7s ease-in-out infinite;

    @keyframes blink {
      50% { opacity: 0; }
    }
  }

  .col {
    ${({ theme: { grid } }) => _times(grid.cols, (i) => `
      &-${i + 1} {
        width: ${((i + 1) / grid.cols) * 100}%;
      }
    `)}
  }

  ${({ theme: { grid, queries } }) => Object.keys(queries).map((q) => queries[q]`
    ${_times(grid.cols, (i) => `
      .col-${q}-${i + 1} {
        width: ${((i + 1) / grid.cols) * 100}%;
      }
    `).join('')}
  `)}

  .text-uppercase {
    text-transform: uppercase;
  }

  .text {
    &-center {
      text-align: center;
    }
    &-left {
      text-align: left;
    }
    &-right {
      text-align: right;
    }

    &-success {
      color: ${({ theme }) => theme.colors.textSuccess};
    }
    &-warning {
      color: ${({ theme }) => theme.colors.textWarning};
    }
    &-error {
      color: ${({ theme }) => theme.colors.textError};
    }
    &-info {
      color: ${({ theme }) => theme.colors.textInfo};
    }
  }

  .b {
    &b {
      border-bottom: 1px solid;
    }
    &t {
      border-bottom: 1px solid;
    }
  }

  .d-flex {
    display: flex;
  }
  .items-stretch {
    align-items: stretch;
  }
  .items-center {
    align-items: center;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-end {
    align-items: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-around {
    justify-content: space-around;
  }
  .justify-evenly {
    justify-content: space-evenly;
  }

  .m {
    &x {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          margin-left: ${d} !important;
          margin-right: ${d} !important;
        }
      `)}
    }
    &l {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          margin-left: ${d} !important;
        }
      `)}
    }
    &r {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          margin-right: ${d} !important;
        }
      `)}
    }
    &y {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          margin-top: ${d} !important;
          margin-bottom: ${d} !important;
        }
      `)}
    }
    &b {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          margin-bottom: ${d} !important;
        }
      `)}
    }
    &t {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          margin-top: ${d} !important;
        }
      `)}
    }
  }

  .p {
    &x {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          padding-left: ${d} !important;
          padding-right: ${d} !important;
        }
      `)}
    }
    &l {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          padding-left: ${d} !important;
        }
      `)}
    }
    &r {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          padding-right: ${d} !important;
        }
      `)}
    }
    &y {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          padding-top: ${d} !important;
          padding-bottom: ${d} !important;
        }
      `)}
    }
    &b {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          padding-bottom: ${d} !important;
        }
      `)}
    }
    &t {
      ${spacings.map((d, i) => `
        &-${!i ? d : i - 1} {
          padding-top: ${d} !important;
        }
      `)}
    }
  }
`;
