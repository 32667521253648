import { createGlobalStyle } from 'styled-components';
import 'semantic-ui-css/semantic.min.css';

import resetCss from './resets';
import utilities from './utilities';
import semanticUi from './semantic-ui';
import '@make.tv/lib-web-uicomponents/build/css/main.min.css';

export const GlobalStyle = createGlobalStyle`
  ${resetCss}
  ${utilities}
  ${semanticUi};

  html {
    overflow: auto;
  }

  body {
    background: ${({ theme }) => theme.colors.bg};
    color: ${({ theme }) => theme.colors.text};
    transition: background ${({ theme }) => theme.transitions.fastEase};
    min-width: ${({ theme }) => theme.minSiteWidth}px;
    zoom: max(0.5, min(1, calc(var(--width)) / ${({ theme }) => theme.minSiteWidth}));
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.3334rem;
    padding-right: 1.3334rem;
    flex-grow: 1;
  }

  .box {
    background: ${({ theme }) => theme.colors.boxBg};
    border: 1px solid ${({ theme }) => theme.colors.boxBorder};
    box-shadow: 0 2px 3px 0 ${({ theme }) => theme.colors.boxDropShadow};
    border-radius: ${({ theme }) => theme.radius.md}px;
    transition: background ${({ theme }) => theme.transitions.fastEase};
  }

  .notifications-portal {
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: auto;
    max-width: 300px;
    min-width: 150px;
    display: block;
    z-index: 10001;
  }

  .list {
    list-style: disc;
    padding-inline-start: 40px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  .fixed-center {
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    width: fit-content;
  }
`;
