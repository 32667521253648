import { ROLE_NOC } from 'Constants';

export const RESOLUTION_OPTIONS = [
  { value: 'SD_480I', text: 'SD' },
  // { value: 'HD', text: 'HD' },
  { value: 'HD_720P', text: 'HD 720p' },
  { value: 'HD_1080I', text: 'HD 1080i' },
  { value: 'HD_1080P', text: 'HD 1080p' },
];

export const FEED_TYPE_OPTIONS = [
  { value: 'REGULAR', text: 'Regular Feed (Default)' },
  { value: 'POOL', text: 'Pool Feed' },
  { value: 'RETURN', text: 'Return' },
  { value: 'MONITORING', text: 'Monitoring' },
  { value: 'TEST', text: 'Test' },
  { value: 'LTN_SWITCHING_HUB', text: 'Bundle: LTN + Video Switching Hub', role: ROLE_NOC },
  { value: 'LTN_SAT_9MHZ', text: 'Bundle: LTN + Satellite 9Mhz', role: ROLE_NOC },
  { value: 'LTN_SAT_18MHZ', text: 'Bundle: LTN + Satellite 18Mhz', role: ROLE_NOC },
];

export const RATE_OPTIONS = [
  { value: 5, text: '1-5 Mbps (Default SD)' },
  { value: 10, text: '6-10 Mbps (Default HD)' },
  { value: 20, text: '11-20 Mbps' },
  { value: 50, text: '21-50 Mbps' },
  { value: 100, text: '51-100 Mbps' },
  { value: 150, text: '101-150 Mbps' },
  { value: 200, text: '151-200 Mbps' },
];

// past options required to avoid errors loading details of previous bookings
export const PAST_RATE_OPTIONS = [
  { value: 40, text: '21-40 Mbps' },
  { value: 60, text: '41-60 Mbps' },
  { value: 100, text: '61-100 Mbps' }
];

export const APPROX_OPTIONS = [
  { value: 0, text: 'Firm Booking (Default)' },
  { value: 15, text: 'Approx: +15 min' },
  { value: 30, text: 'Approx: +30 min' },
  { value: 60, text: 'Approx: +60 min' },
  { value: 90, text: 'Approx: +90 min' }
];

export const BOOKING_FLAG_OPTIONS = [
  { value: '', text: '' },
  { value: 'MANAGED', text: 'Managed Event (Check-in Required)' },
  { value: 'MANAGED_CHECKED', text: 'Managed Event Checked (Check-in Completed)' },
];

export const EXCEPTION_FLAG_OPTIONS = [
  { value: '', text: '' },
  { value: 'UNRESOLVED', text: 'Unresolved' },
  { value: 'RESOLVED', text: 'Resolved' },
];

export const EXCEPTION_TYPE_OPTIONS = [
  { value: '', text: '' },
  { value: 'AMOUNT', text: 'Event Bill Amount Override' },
  { value: 'DURATION', text: 'Event Bill Total Duration Override (in minutes)' },
  { value: 'RATE', text: 'Event Bill Hourly Rate Override' },
  { value: 'VOID', text: 'Void charges for this event. Add details below.' },
  { value: 'START', text: 'DO NOT USE YET Event Bill Start Time Override' },
  { value: 'STOP', text: 'DO NOT USE YET Event Bill Stop Time Override' },
  { value: 'OFFSET', text: 'DO NOT USE YET Event Bill Time Offset ( - or + )' },
];
