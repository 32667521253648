import { lazy } from 'react';
import { PERMISSION_REPORT } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: PERMISSION_REPORT },
  path: 'report',
  title: 'Report',
  urlParams: '/:type?',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/bookings',
      component: lazy(() => import('./views/bookings-report')),
      title: 'Bookings',
      pageTitle: 'Bookings Report'
    },
    {
      path: '/usage',
      component: lazy(() => import('./views/usage-report')),
      title: 'Usage',
      pageTitle: 'Usage Report',
    },
  ],
};
