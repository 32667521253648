const ENV = process.env.NODE_ENV;

export const IS_PROD = ENV === 'production';
export const IS_DEV = ENV === 'development';
export const IS_LOCAL = ENV === 'local';

export const env = {
  ...process.env,
};

let domain = '';

if (env.REACT_APP_ENV === 'prod') {
  domain = 'www.ltnportal.com';
} else if (env.REACT_APP_ENV === 'stage') {
  domain = 'stage.ltnportal.com';
} else if (env.REACT_APP_ENV === 'test') {
  domain = 'test.ltnportal.com';
}

export const DOMAIN = domain;
