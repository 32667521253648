import axios, { CancelToken } from 'axios';
import * as cookieStorage from 'Services/cookies.service';
import { API_URL, API_KEY, LS_NAMESPACE } from 'Constants';

axios.interceptors.request.use(
  (config) => {
    let url;
    // if no host is given for the api call, use the API_URL constant
    try {
      url = new URL(config.url);
    } catch {
      url = new URL(`${API_URL}${config.url}`);
    }
    config.url = url.href;

    // Add the bearer token & the api key only to the ltn portal api calls
    const allowedOrigins = [new URL(API_URL).origin];

    if (allowedOrigins.includes(url.origin)) {
      const token = JSON.parse(
        localStorage.getItem(`${LS_NAMESPACE}/authToken`)
        || sessionStorage.getItem(`${LS_NAMESPACE}/authToken`)
        || cookieStorage.getItem(`${LS_NAMESPACE}/authToken`)
      );
      config.headers.authorization = config.headers.authorization || `Bearer ${token}`;
      config.headers['x-api-key'] = API_KEY;
    }

    config.paramsSerializer = (params) => Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    if (config.cancelToken?.exec === null) {
      config.cancelToken = new CancelToken((c) => { config.cancelToken.exec = c; });
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use((response) => {
  cookieStorage.setItem(`${LS_NAMESPACE}/server-time`, response.headers.date);
  localStorage.setItem(`${LS_NAMESPACE}/server-time`, response.headers.date);

  const localTimeDiff = new Date() - new Date(response.headers.date);
  cookieStorage.setItem(`${LS_NAMESPACE}/local-time-diff`, localTimeDiff);
  localStorage.setItem(`${LS_NAMESPACE}/local-time-diff`, localTimeDiff);

  return response;
});

export { axios as Api };
