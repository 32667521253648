import React from 'react';
import { Message } from 'semantic-ui-react';

import { useNotifications } from 'Context/notifications';

import { Portal } from './portal';

export const Notifications = () => {
  const { notifications, hide } = useNotifications();

  return (
    <Portal className="notifications-portal">
      {notifications.map(({ id, data }) => (
        <Message {...data} key={id} onClick={() => hide({ id })} />
      ))}
    </Portal>
  );
};
