import React, { Suspense, useMemo } from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';
import _get from 'lodash/get';

import { PrivateRoute } from 'Components/auth/private-route';
import { PublicRoute } from 'Components/auth/public-route';
import { PageLoader } from 'Components/layout/page-loader';

import { ErrorBoundary } from './error-boundary';
import routes, { authenticatedRoutes } from './routes-config';

const RouteWithSubRoutes = ({ route }) => {
  const { path } = useRouteMatch();

  const childRoutes = useMemo(() => (
    !route.children ? null : (
      <>
        {route.children.map((subroute) => (
          <RouteWithSubRoutes route={subroute} key={subroute.path} />
        ))}
      </>
    )
  ), [route.children]);

  const RouteType = useMemo(() => (
    {
      private: PrivateRoute,
      public: PublicRoute,
    }[_get(route.auth, 'type')] || Route
  ), [route.auth]);

  const routeComponent = useMemo(() => (
    route.path === undefined
      ? <>{childRoutes}</>
      : (
        <RouteType
          path={`${path}${route.path}${route.urlParams || ''}`}
          exact={route.exact || !route.path}
          {...route.auth}
          component={(props) => (
            route.component ? <route.component routes={childRoutes} {...props} /> : childRoutes
          )}
          key={route.path}
        />
      )
  ), [childRoutes, path, route.auth, route.component, route.exact, route.path, route.urlParams]);

  return routeComponent;
};

export const AppRouter = () => (
  <ErrorBoundary>
    <Suspense fallback={<PageLoader />}>
      {/* all available app routes */}
      <RouteWithSubRoutes route={{ children: routes }} />
      {/* redirect / to first authenticated route */}
      <Route
        path="/"
        exact
        component={() => <Redirect to={authenticatedRoutes[0].path} />}
      />
    </Suspense>
  </ErrorBoundary>
);
