import { lazy } from 'react';
import { PERMISSION_WATCH } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: PERMISSION_WATCH },
  path: 'watch',
  urlParams: '/:viewId?',
  title: 'Watch',
  component: lazy(() => import('./index')),
};
