import React from 'react';
import { format as fm, parse, addDays } from 'date-fns';

import { readServerTime } from 'Components/current-time';

/** **************************************************************** */
/** ********* FORMAT FROM UTC TO USER PREFERENCE TIMEZONE ********** */
/** **************************************************************** */

export const formatToTimezone = (date, tz = 'GMT') => {
  const fmdate = new Date(
    date
  ).toLocaleString('en-GB', { timeZone: { ET: 'America/New_York' }[tz] || tz });

  return parse(fmdate, 'dd/MM/yyyy, HH:mm:ss', new Date(readServerTime()));
};

/**
 * Date formatter component
 * @param {string} format The format stringfor the date
 * @param {string|Date} date The date to be formatted
 */
export const DateFm = ({
  format, date, tz = 'GMT', ...opts
}) => {
  const dateValue = (() => {
    try {
      const dateObj = formatToTimezone(date.toString(), tz);
      return fm(dateObj, format, { ...opts });
    } catch (e) {
      return 'Invalid Date';
    }
  })();

  if (!date) {
    return '';
  }

  return (
    <>
      {dateValue}
    </>
  );
};

/** **************************************************************** */
/** ********* FORMAT FROM USER PREFERENCE TIMEZONE TO UTC ********** */
/** **************************************************************** */

const getFirstSundayOnOrAfter = (dt) => {
  const day = dt.getUTCDay();

  if (day === 0) {
    return dt;
  }

  return addDays(dt, 7 - day);
};

const getUserTzOffset = (utcTime, tz) => {
  if (tz === 'ET') {
    const year = new Date(utcTime).getUTCFullYear();

    const dstMarker = new Date(new Date(Date.UTC(1, 2, 8, 2)).setUTCFullYear(year));
    const estMarker = new Date(new Date(Date.UTC(1, 10, 1, 2)).setUTCFullYear(year));

    const startDst = getFirstSundayOnOrAfter(dstMarker);
    const startEst = getFirstSundayOnOrAfter(estMarker);

    const isDstObserved = (time) => time >= startDst && time < startEst;

    if (isDstObserved(utcTime)) {
      return 4 * 60 * 60 * 1000;
    }
    return 5 * 60 * 60 * 1000;
  }

  return 0;
};

export const formatToUTC = (dt, tz) => {
  const localTime = dt.getTime();

  const localOffset = dt.getTimezoneOffset() * 60 * 1000;
  const utcTime = localTime - localOffset;

  const tzOffset = getUserTzOffset(utcTime, tz);
  const tzTime = utcTime + tzOffset;

  return new Date(tzTime);
};
