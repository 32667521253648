const debugMode = Boolean(window.location.search.match(/debugLogging/)) || JSON.parse(sessionStorage.getItem('debugMode'));
const winConsole = window.console;

sessionStorage.setItem('debugMode', debugMode);

/**
 * Show debugging messages if the loading url contains `debugLogging` as query param
 * @param {*} type Type of message to display
 * @param  {...any} args Anything that needs to be displayed
 */
export const debug = (type = 'log', ...args) => {
  if (!debugMode || !winConsole) {
    return undefined;
  }

  if (typeof type !== 'string' || !winConsole[type]) {
    return winConsole.log(...args);
  }

  return winConsole[type](...args);
};

export const log = debug.bind(null, 'log');
export const error = debug.bind(null, 'error');

export default {
  debug,
  log,
  error,
};
