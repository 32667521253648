import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppProviders } from 'Context';
import { AppLayout } from 'Components/layout';
import { AppRouter } from 'Routes/app-router';

export default () => (
  <Router>
    <AppProviders>
      <AppLayout>
        <AppRouter />
      </AppLayout>
    </AppProviders>
  </Router>
);
