import React from 'react';

import { AuthProvider } from './auth';
import { UserProvider } from './user';
import { ThemeCtxProvider } from './theme';
import { AppQuery } from './app-query';
import { NotificationsCtxProvider } from './notifications';
import { NavContextProvider } from './nav-context';

export function AppProviders({ children }) {
  return (
    <NotificationsCtxProvider>
      <AuthProvider>
        <AppQuery>
          <UserProvider>
            <NavContextProvider>
              <ThemeCtxProvider>
                {children}
              </ThemeCtxProvider>
            </NavContextProvider>
          </UserProvider>
        </AppQuery>
      </AuthProvider>
    </NotificationsCtxProvider>
  );
}
