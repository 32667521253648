export const CHANNEL_TYPE_OPTIONS = [
  { value: 'LINEAR_CONTRIBUTION', text: 'Linear Contribution' },
  { value: 'LINEAR_DISTRIBUTION', text: 'Linear Distribution' },
  { value: 'OCCASIONAL_USE', text: 'Ocassional Use' },
  { value: 'OTHER', text: 'Other' },
];

export const CHANNEL_TRANSPORT_TYPES = [
  { value: '', text: '' },
  { value: 'UDP', text: 'TS/UDP' },
  { value: 'RTP', text: 'TS/RTP' },
  { value: 'MPTS', text: 'MPTS/UDP' },
  { value: 'STL', text: 'STL/TP' },
];

export const CHANNEL_TRANSPORT_VIDEO_TYPES = [
  { value: '', text: '' },
  { value: 'MPEG2', text: 'MPEG2' },
  { value: 'MPEG4', text: 'MPEG4' },
];
