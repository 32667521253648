import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from 'Context/auth';
import { Typography } from 'Components/typography';

import * as styles from './styles';

/**
 * Component for the page footer
 */
export const PageFooter = ({ className }) => {
  const { isAuthenticated } = useAuth();

  return (
    <styles.Wrap>
      <div className={`container d-flex items-center justify-between ${className}`}>
        <Link to="/">
          <styles.Logo alt="LTN" />
        </Link>
        <Typography
          variant="caption"
          style={{
            fontSize: '10px',
            opacity: '.6',
            padding: '0 75px',
            textAlign: 'center',
            height: '46px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          The content displayed on this website is for customer internal use only. For general
          information visit our company website at https://www.ltnglobal.com or contact us at
          (301) 363-1001 | © 2010-2024 LTN Global Communications, Inc. All rights reserved.
          {!isAuthenticated && (
            <>
              <br />
              Note: This page does not use any cookies.
            </>
          )}
        </Typography>
        <div style={{ width: '50px' }} />
      </div>
    </styles.Wrap>
  );
};
