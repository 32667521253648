import { useCallback, useEffect, useState } from 'react';

import { LS_NAMESPACE } from 'Constants';
import debug from 'Utils/logger';
import * as cookieStorage from 'Services/cookies.service';

const storageTypes = {
  cookieStorage,
  localStorage: window.localStorage,
  sessionStorage: window.sessionStorage,
};

export function useStorage(storageType, key, initialValue, storageOptions) {
  const storage = storageTypes[storageType];
  const storageKey = `${LS_NAMESPACE}/${key}`;

  const readStoredValue = useCallback((value) => () => {
    try {
      // Get from local storage by key
      const item = storage.getItem(storageKey);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : value;
    } catch (error) {
      // If error also return value
      return value;
    }
  }, [storage, storageKey]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(readStoredValue(initialValue));

  // Return a wrapped version of useState's setter function that
  // persists the new value to local or session storage.
  const setValue = useCallback((value, options) => {
    try {
      // Allow value to be a function so we have same API as useState
      setStoredValue((storedv) => {
        const valueToStore = value instanceof Function ? value(storedv) : value;

        if (valueToStore === undefined) {
          storage.removeItem(storageKey);
        } else {
          // Save to local storage
          storage.setItem(storageKey, JSON.stringify(valueToStore), options ?? storageOptions);
        }

        return valueToStore;
      });
    } catch (error) {
      // A more advanced implementation would handle the error case
      debug.error(error);
    }
  }, [storage, storageKey, storageOptions]);

  /**
   * ENABLED when storageOptions.listenForChanges = true
   * This keeps track of changes across tabs to the localStorage
   *  and updates the current view with the changed data
   */
  useEffect(() => {
    if (storageType !== 'localStorage') {
      return () => {};
    }

    const updateStoredValue = ({ key: changedKey }) => {
      if (!storageOptions?.listenForChanges || changedKey !== storageKey) {
        return;
      }

      const value = readStoredValue(storedValue)();
      try {
        // Allow value to be a function so we have same API as useState
        setStoredValue((storedv) => (
          value instanceof Function ? value(storedv) : value
        ));
      } catch (error) {
        // A more advanced implementation would handle the error case
        debug.error(error);
      }
    };

    window.addEventListener('storage', updateStoredValue, false);
    return () => {
      window.removeEventListener('storage', updateStoredValue, false);
    };
  }, [readStoredValue, storageKey, storageOptions, storageType, storedValue]);

  return [storedValue, setValue];
}

export const useLocalStorage = useStorage.bind(null, 'localStorage');
export const useSessionStorage = useStorage.bind(null, 'sessionStorage');
