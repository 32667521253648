import styled from 'styled-components';

import { IconEditRemove } from '@make.tv/lib-web-uicomponents';

import { modifier } from 'Styles/utils';

export const SubNav = styled.div`
  display: flex;

  .button {
    margin: 0 4px;
  }

  .button#newBookingBtn {
    margin: 0 8px 0 12px;
  }
`;

export const AccountId = styled.div`
  ${modifier('warn')`
    color: ${({ theme }) => theme.colors.textWarning};
  `}

  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
`;

export const ImpersonationExit = styled(IconEditRemove).attrs({ size: 'sm' })`
  margin-left: 3px;
  color: ${({ theme }) => theme.colors.textWarning};
  &:hover {
    cursor: pointer;
  }
`;

export const MessageStatusContainer = styled.div`
  background: rgba(200, 121, 25, 0.3);
  border-radius: 2px;
  display: flex;
  justify-content: center;
`;
