import get from 'lodash/get';

import * as Resource from './resource';

/**
 * Fetch a list of bookings with billings data
 */
export const listWithBillings = (_resource, params, config = {}) => (
  Resource.list('booking_with_billings', params, config)
    .then((data) => ({
      bookings: get(data, 'booking_with_billings', []).map(({ booking, billing }) => ({
        ...billing,
        ...booking,
      })),
      total_size: data.total_size,
    }))
);

/**
 * Fetch a booking with billings data
 */
export const getWithBillings = (_resource, id, params, config = {}) => (
  Resource.get('booking_with_billings', id, params, config)
    .then(({ booking, billing }) => ({
      ...billing,
      ...booking,
    }))
);

/**
 * Fetch a list of past booking records
 */
export const listPastRecords = (bookingId, params, config = {}) => (
  Resource.list('past_booking_records', params, config)
    .then((data) => ({
      past_booking_records: get(data, 'past_booking_records', []).map(({ booking }) => booking),
      total_size: data.total_size,
    }))
);

/**
 * Fetch a list of contacts for a booking leaf
 */
export const listLeafContacts = (leafId) => (
  Resource.get('leaves', leafId, {
    'field_mask.endpoint_id': '',
    field_mask_pstv: true,
  })
    .then((leaf) => (
      Resource.get('endpoints', leaf.endpoint_id, {
        'field_mask.endpoint_id': '',
        'field_mask.contact_ids': '',
        field_mask_pstv: true,
      })
        .then((endpoint) => {
          if (!('contact_ids' in endpoint)) {
            return { contacts: [], totalCount: 0, endpointId: endpoint.endpoint_id };
          }
          const contactIds = endpoint.contact_ids.map((d) => `'${d}'`).join();

          return Resource.list('contacts', {
            filter: `contact_id in (${contactIds})`,
            'field_mask.contact_id': '',
            'field_mask.contact_name': '',
            'field_mask.title': '',
            'field_mask.contact_role': '',
            'field_mask.phone1': '',
            'field_mask.phone2': '',
            'field_mask.email1': '',
            field_mask_pstv: true,
          })
            .then(({ contacts, total_size: totalCount }) => ({
              contacts,
              totalCount,
              endpointId: endpoint.endpoint_id,
            }));
        })
    ))
);
