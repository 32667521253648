import { lazy } from 'react';

export default {

  ui: 'menuUser',

  path: 'profile',
  title: 'Profile',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/profile-view',
      component: lazy(() => import('./views/profile-view')),
      title: 'Edit Profile',
      pageTitle: 'Edit Profile'
    },
    {
      path: '/mfa-view',
      component: lazy(() => import('./views/mfa-view')),
      title: 'MFA Details',
      pageTitle: 'MFA Details'
    },
    {
      path: '/long_lived_tokens',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/long-lived-tokens-view')),
      title: 'Long Lived Tokens',
      pageTitle: 'Long Lived Tokens',
    },
  ],
};
