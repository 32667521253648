export const FLOWCLIENT_TYPE_OPTIONS = [
  { value: 'INGEST', text: 'Ingest' },
  { value: 'RECEIVER', text: 'Receiver' },
];

export const FLOWCLIENT_APPLIANCE_OPTIONS = [
  { value: 0, text: 'Default (Single on single site, Redundant on redundant site)' },
  { value: 1, text: 'Single on Redundant Setup on Appliance 1' },
  { value: 2, text: 'Single on Redundant Setup on Appliance 2' },
];
