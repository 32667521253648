import get from 'lodash/get';

import * as Resource from './resource';

/**
 * Fetch a list of bookings with billings data
 */
export const listDirectoryWithAddresses = (_resource, params, config = {}) => (
  Resource.list('directory_entry_with_addresses', params, config)
    .then((data) => ({
      directory_entry_with_addresses: get(data, 'directory_entry_with_addresses', []).map(({ address, directory_entry: entry }) => ({
        ...address,
        ...entry,
      })),
      total_size: data.total_size,
    }))
);
