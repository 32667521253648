import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUser } from 'Context/user';
import { useAuth } from 'Context/auth';
import { useRouter } from 'Context/hooks';

/**
 * CAUTION: Use this route type only if the content in it is available
 * just to the non-logged user (eg. the login route)
 */
export const PublicRoute = ({ component: Component, ...rest }) => {
  const { pathname, location: { state } } = useRouter();
  const { dataReady } = useUser();
  const { isAuthenticated } = useAuth();

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(props) => {
        if (!dataReady) {
          return null;
        }

        return (!isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: state?.from ? state.from : '/',
            state: { from: pathname }
          }}
          />
        ));
      }}
    />
  );
};
