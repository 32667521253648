export const SDI_CARD_OPTIONS = [
  { value: '', text: '' },
  { value: 'SINGLE', text: 'SINGLE -- Encode Capable Port (1)' },
  { value: 'DUO', text: 'DUO -- Encode Capable Ports (2), Decode Capable Ports (2)' },
  { value: 'DUO2', text: 'DUO2 -- Encode/Decode Capable Ports (4)' },
  { value: 'SDI4K', text: 'SDI4K -- Encode/Decode Capable Ports (1)' },
  { value: 'QUAD2', text: 'QUAD2 -- Encode/Decode Capable Ports (8)' },
  { value: '8KPRO', text: '8KPRO -- Encode/Decode Capable Ports (4)' },
  { value: 'RECORDER4K', text: 'RECORDER4K -- Encode Capable Port (1)' },
  { value: 'NDI', text: 'NDI -- Encode/Decode Capable Ports (8)' },
];
