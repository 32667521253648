import { DOMAIN } from 'Constants';

/**
 * @name CookieService
 * Utility service to treat cookies as a storage type
 * Has the same API as localStorage and sessionStorage
 */

export const getItem = (sKey) => decodeURIComponent(document.cookie.replace(new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1')) || null;

export const setItem = (sKey, sValue, {
  vEnd,
  sPath,
  sDomain = DOMAIN,
  bSecure,
} = {}) => {
  if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) { return false; }
  let sExpires = '';
  if (vEnd) {
    switch (vEnd.constructor) {
      case Number:
        sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : `; max-age=${vEnd}`;
        break;
      case String:
        sExpires = `; expires=${vEnd}`;
        break;
      case Date:
        sExpires = `; expires=${vEnd.toUTCString()}`;
        break;
      default:
        break;
    }
  }

  document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)}${sExpires}${sDomain ? `; domain=${sDomain}` : ''}; path=${sPath || '/'}${bSecure ? '; secure' : ''};`;
  return true;
};

export const hasItem = (sKey) => (new RegExp(`(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=`)).test(document.cookie);

export const removeItem = (sKey, sPath, sDomain = DOMAIN) => {
  if (!sKey || !hasItem(sKey)) { return false; }
  document.cookie = `${encodeURIComponent(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${sDomain ? `; domain=${sDomain}` : ''}${sPath ? `; path=${sPath}` : ''};`;
  return true;
};

export const keys = () => {
  const aKeys = document.cookie.replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:=[^;]*)?;\s*/);
  for (let nIdx = 0; nIdx < aKeys.length; nIdx += 1) {
    aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
  }
  return aKeys;
};
