import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ children, parent, className }) => {
  // Create div to contain everything
  const el = useMemo(() => document.createElement('div'), []);

  // On mount
  useEffect(() => {
    // work out target in the DOM based on parent prop
    const target = parent && parent.appendChild ? parent : document.body;

    const classList = ['portal-container', ...(className || '').split(' ').filter(Boolean)];

    classList.forEach((item) => el.classList.add(item));
    // Append element to dom
    target.appendChild(el);

    // On unmount Remove element from dom
    return () => target.removeChild(el);
  }, [el, parent, className]);

  return ReactDOM.createPortal(children, el);
};
