import { lazy } from 'react';
import { PERMISSION_NOC, PERMISSION_CLIENT_API_DOCS } from 'Constants';

export default {
  ui: 'menuRight',
  auth: { role: PERMISSION_CLIENT_API_DOCS },
  path: 'api',
  urlParams: '/:page?',
  title: 'API',
  component: lazy(() => import('./index')),
  children: [
    {
      auth: { role: PERMISSION_NOC },
      path: '/transport-docs',
      component: () => null,
      title: 'Transport API Documentation',
      pageTitle: 'Transport API Documentation',
    },
    {
      auth: { role: PERMISSION_CLIENT_API_DOCS },
      path: '/client-docs',
      component: () => null,
      title: 'Client API Documentation',
      pageTitle: 'Client API Documentation',
    },
  ],
};
