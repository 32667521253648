import { memo } from 'react';

import { APP_PAGE_TITLE } from 'Constants';
import { useRouter } from 'Context/hooks';

export const PageTitle = memo(({ title }) => {
  const { activeRoute } = useRouter();
  const route = activeRoute.slice(-1)[0] || {};
  const pageTitle = title || route.pageTitle || route.title || '';

  document.title = `${APP_PAGE_TITLE} ${pageTitle && `- ${pageTitle}`}`;

  return null;
});
