import { lazy } from 'react';
import { PERMISSION_MONITOR } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: PERMISSION_MONITOR },
  path: 'notifications',
  urlParams: '/:type?',
  title: 'Notifications',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/active',
      component: () => null,
      title: 'Active',
      pageTitle: 'Active Notifications'
    },
    {
      path: '/ended',
      component: () => null,
      title: 'Recently Ended',
      pageTitle: 'Recently Ended Notifications',
    },
  ],
};
