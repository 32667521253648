import * as Resource from './resource';

/**
 * Impersonate an account
 * @param {string} userId Current user's ID
 * @param {string} accountId The account ID to impersonate
 */
export const impersonate = (userId, accountId) => (
  Resource.patch('users', userId, {
    user: { emulated_account_id: accountId },
    field_mask_pstv: true,
  })
);

/**
 * Set user's theme preference
 * @param {string} userId Current user's ID
 * @param {string} theme The theme preference
 */
export const setTheme = (userId, theme) => (
  Resource.patch('users', userId, {
    user: { theme },
    field_mask_pstv: true,
  })
);
