import React from 'react';
import styled, { keyframes } from 'styled-components';

const loadingAnimFrames = keyframes`
  0% {
    animation-timing-function: cubic-bezier(.5,0,.70173,.49582);
    width: 0;
    left: 0;
  }
  80% {
    width: 100%;
    left: 0;
  }
  90% {
    animation-timing-function: cubic-bezier(.30244,.38135,.55,.95635);
    width: 100%;
    left: auto;
  }
  100% {
    width: 0;
    left: auto;
  }
`;

const LoaderInicator = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  z-index: 999;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => theme.colors.primary};
    transform-origin: top left;
    animation: ${loadingAnimFrames} 2s linear infinite;
  }
`;

/**
 * Progress bar indicating the current page is loading
 */
export const PageLoader = () => (
  <LoaderInicator />
);
