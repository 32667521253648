export const SORT_ASC = 1;
export const SORT_DESC = -1;

export const COLOR_RANK = {
  GRAY: 1,
  GREEN: 2,
  PURPLE: 3,
  YELLOW: 5,
  ORANGE: 5,
  RED: 6,
};

export const PRIORITY_RANK = {
  URGENT: 1,
  HIGH: 2,
  NORMAL: 3,
  LOW: 3,
  OK: 5
};
