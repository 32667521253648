import React, { memo, useCallback } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { useAuth } from 'Context/auth';
import { useUser } from 'Context/user';
import { APP_LOGIN_ROUTE } from 'Constants';
import { deepDiff } from 'Utils/deep-diff';
import debug from 'Utils/logger';

const MemoRoute = memo(({
  isAuthenticated,
  hasRole,
  pathname,
  dataReady,
  component: Component,
  role,
  props
}) => {
  if (!dataReady) {
    debug.log('%cPrivateRoute', 'color: blue', 'data not ready');
    return null;
  }

  if (!isAuthenticated) {
    window.console.log('Not authenticated');
    return (
      <Redirect
        to={{
          pathname: APP_LOGIN_ROUTE,
          state: { from: pathname }
        }}
      />
    );
  }

  if (!Array.isArray(role)) {
    role = [role];
  }

  if (role.some((r) => hasRole(r))) {
    debug.log('%cPrivateRoute', 'color: blue', 'Authenticated and has role');
    return (<Component {...props} />);
  }

  debug.log('%cPrivateRoute', 'color: blue', 'Authenticated but doesn\'t have role', role);
  return (<Redirect to={{ pathname: '/contact' }} />);
}, (prev, next) => {
  const diff = deepDiff(prev, next);
  debug.log('%cRouteReRenderDiff', 'color: blue; font-weight: bold', JSON.stringify(diff, null, 2));
  return isEmpty(diff);
});

/**
 * Restrict route to logged users only (it can also check for a specific user role)
 */
export const PrivateRoute = ({
  component: Component, role, path, exact
}) => {
  const { pathname } = useLocation();
  const { dataReady, hasRole } = useUser();
  const { isAuthenticated } = useAuth();

  const render = useCallback((props) => (
    <MemoRoute
      isAuthenticated={isAuthenticated}
      hasRole={hasRole}
      pathname={pathname}
      dataReady={dataReady}
      component={Component}
      role={role}
      props={props}
    />
  ), [Component, dataReady, hasRole, isAuthenticated, pathname, role]);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route path={path} exact={exact} render={render} />
  );
};
