import { Api } from 'Services/api.service';
import lGet from 'lodash/get';

/**
 * Catch an api error, and convert it to an easier to use error
 * @param {*} e The API error
 */
const catchError = (e) => {
  const { response = {} } = e;
  const { status = 500, data = {} } = response;
  const { message = '', code } = data;

  return Promise.reject({ status, message, code });
};

/**
 * Get the endpoint url based on provided ids
 * @param {string} resource Resource endpoint
 * @param {string} id The resource ID
 * @param {string} id2 The secondary resource ID
 */
const getResourceIdUrl = (resource, id, id2) => {
  let url;
  if (id2) {
    url = `/${resource}/${encodeURIComponent(id)}/${encodeURIComponent(id2)}`;
  } else {
    url = `/${resource}/${encodeURIComponent(id)}`;
  }
  return url;
};

const currentLists = {};

async function listWithCache(resource, params, config) {
  const key = resource.toString() + JSON.stringify(params) + JSON.stringify(config);

  if (key in currentLists) {
    return currentLists[key];
  }
  const data = Api.get(`/${resource}`, {
    ...config,
    params
  });
  currentLists[key] = data;
  const response = await data;
  delete currentLists[key];
  return response;
}

/**
 * Fetch a list of resources
 * @param {string} resource Resource endpoint
 * @param {any} params Additional query params
 * @param {*} config Additional request configurations. See https://github.com/axios/axios#request-config
 */
export const list = (resource, params, config = {}) => listWithCache(resource, params, config)
  .then(({ data }) => data).catch(catchError);

/**
 * Fetch the whole list of resources
 * This will do an initial request with limit=1, just to get the total count of resources
 *
 * @param {string} resource Resource endpoint
 * @param {any} params Additional query params
 * @param {*} config Additional request configurations. See https://github.com/axios/axios#request-config
 */
export const listAll = (resource, params, config = {}) => listWithCache(
  resource,
  { ...params, page_size: 9999999 },
  config
).then(({ data }) => data).catch(catchError);

/**
 * Fetch a resource entry by id
 * @param {string} resource Resource endpoint
 * @param {string} id The resource ID
 * @param {string} id2 The secondary resource ID
 * @param {any} params Additional query params
 * @param {*} config Additional request configurations. See https://github.com/axios/axios#request-config
 */
export const get = (resource, id, params, config = {}, id2) => Api.get(
  getResourceIdUrl(resource, id, id2), {
    ...config,
    params
  }
).then(({ data }) => data).catch(catchError);

/**
 * Do a post request to a resource endpoint
 * @param {string} resource Resource endpoint
 * @param {any} data The data to be passed as body to the request
 * @param {any} params Additional query params
 * @param {*} config Additional request configurations. See https://github.com/axios/axios#request-config
 */
export const post = (resource, data, params, config = {}) => Api.post(`/${resource}`, data, {
  ...config,
  params
}).then(({ data: response }) => response).catch(catchError);

/**
 * Do a patch request to a resource endpoint
 * @param {string} resource Resource endpoint
 * @param {string} id The resource ID
 * @param {string} id2 The secondary resource ID
 * @param {any} data The data to be passed as body to the request
 * @param {any} params Additional query params
 * @param {*} config Additional request configurations. See https://github.com/axios/axios#request-config
 */
export const patch = (resource, id, data, params, config = {}, id2) => Api.patch(
  getResourceIdUrl(resource, id, id2), data, {
    ...config,
    params
  }
).then(({ data: response }) => response).catch(catchError);

/**
 * Do a patch request to a resource endpoint
 * @param {string} resource Resource endpoint
 * @param {any} data The data to be passed as body to the request
 * @param {any} params Additional query params
 * @param {*} config Additional request configurations. See https://github.com/axios/axios#request-config
 */
export const patchNoId = (resource, data, params, config = {}) => Api.patch(`/${resource}`, data, {
  ...config,
  params
}).then(({ data: response }) => response).catch(catchError);

export const save = (resource, idField, data, params, config = {}) => {
  const id = lGet(data, [...idField.split('.')]);
  return id
    ? patch(resource, id, data, params, config)
    : post(resource, data, params, config);
};

/**
 * Do a delete request to a resource endpoint
 * @param {string} resource Resource endpoint
 * @param {string} id The resource ID
 * @param {string} id2 The secondary resource ID
 * @param {any} params Additional query params
 * @param {*} config Additional request configurations. See https://github.com/axios/axios#request-config
 */
export const del = (resource, id, params?, config = {}, id2) => Api.delete(
  getResourceIdUrl(resource, id, id2), {
    ...config,
    params
  }
).then(({ data: response }) => response).catch(catchError);

/**
 * Get all available resource methods (list/get/etc) for a specific resource endpoint
 * @param {string} resource
 */
export const getResource = (resource) => ({
  list: list.bind(null, resource),
  listAll: listAll.bind(null, resource),
  get: get.bind(null, resource),
  post: post.bind(null, resource),
  patch: patch.bind(null, resource),
  del: del.bind(null, resource),
});
