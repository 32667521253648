import each from 'lodash/each';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';

/* eslint-disable */
export const deepDiff = (function() {
  function inDiff(a, b, r) {
    each(a, function(v, k) {
      // already checked this or equal...
      if (r.hasOwnProperty(k) || b[k] === v) return;
      // but what if it returns an empty object? still attach?
      const diff = isObject(v) ? deepDiff(v, b[k]) : v;
      if (!isObject(diff) || !isEmpty(diff)) {
        r[k] = diff;
      }
    });
  }

  /* the function */
  return function(a, b) {
    const r = {};
    const adiff = a || {};
    const bdiff = b || {};
    inDiff(adiff, bdiff, r);
    inDiff(bdiff, adiff, r);
    return r;
  }
})();
/* eslint-enable */
